import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import store from '@/store'
import awsConnection from '@/views/habit/aws'
import realmConnection from '@/views/habit/realm'

export default function useCommonTodo() {

  const userData = store.state?.userStore?.userData;
  const { default_language, commitment_functionality: commitmentFunctionality } = JSON.parse(localStorage.getItem('clientData') || '{}')
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { singleUpload, sendEmail } = awsConnection()
  const { createItem, updateItem, updateItems, getItem, getItems, getItemsWithAggregate, ObjectId } = realmConnection()

  const tagColors = {
    people: "success",
    place: "info",
    processes: "warning",
    customer_need: "danger",
    tools_team: "info",
    positive_client_voice: "success",
    negative_client_voice: "danger",
    safety: "success",
    processes_productivity: "info",
    assets_management: "warning",
    quality: "danger",
    sustainability: "secondary",
    costs: "dark",
    rdp: "success",
  }

  const resolveTagVariant = tag => {
    return tagColors[tag] || "success"
  }
  
  const resolveAvatarVariant = tags => {
    if (!tags || !tags.length) return 'secondary'
    for (const tag of tags) {
      if (tagColors.hasOwnProperty(tag)) return tagColors[tag]
    }
    return 'success'
  }

  const updateImprovementWithKey = async (improvementId, key) => {
    try {
      const query = { _id: ObjectId(improvementId) }
      const action = { $set: { imageKey: key } }

      await updateItem({ collection: 'improvement', query, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.improvement_update_error'))
    }
  }

  const getEmailImprovementTemplate = bodyData => {
    const { name, title, assignee, tags, creator, dueDate, description, creation_date, instance, instance_leader, zone, commitmentFunctionality, fromProblemSolving, updatedState, completed, deleted, category, id } = bodyData
    return `
      <html>
        <table style="max-width: 600px; padding: 10px; margin:0 auto; border-collapse: collapse; border-radius: 1em; overflow: hidden;">
          <tr>
            <td style="background-color: #ecf0f1; text-align: center; padding: 0">
              <img width="20%" style="margin: 2% 0% 1%" src="https://production-habit.s3.amazonaws.com/habit/Habit+Logo.png" alt="Habit Logo">
            </td>
          </tr>
          
          <tr>
            <td style="background-color: #ecf0f1">
              <div style="color: #34495e; margin: 2% 10%; font-family: sans-serif">
                <h2 style="color: #e67e22; margin: 0 0 7px">
                  ${i18n.t('message.hello')}${name ? ` ${name}` : ''},
                </h2>

                <p style="margin: 2px; font-size: 15px">
                  ${commitmentFunctionality
                    ? updatedState === "delete"
                      ? category === "assignee" ? i18n.t('message.commitment_assigned_deleted') : i18n.t('message.commitment_subscribed_deleted')
                      : updatedState === "complete"
                        ? category === "assignee" ? i18n.t('message.commitment_assigned_completed') : i18n.t('message.commitment_subscribed_completed')
                        : updatedState === "update"
                          ? category === "assignee" ? i18n.t('message.commitment_assigned_updated') : i18n.t('message.commitment_subscribed_updated')
                          : category === "assignee" ? i18n.t('message.assigned_responsible_commitment') : i18n.t('message.assigned_subscribed_commitment')
                    : updatedState === "delete"
                      ? category === "assignee" ? i18n.t('message.improvement_assigned_deleted') : i18n.t('message.improvement_subscribed_deleted')
                      : updatedState === "complete"
                        ? category === "assignee" ? i18n.t('message.improvement_assigned_completed') : i18n.t('message.improvement_subscribed_completed')
                        : updatedState === "update"
                          ? category === "assignee" ? i18n.t('message.improvement_assigned_updated') : i18n.t('message.improvement_subscribed_updated')
                          : category === "assignee" ? i18n.t('message.assigned_responsible_improvement') : i18n.t('message.assigned_subscriber_improvement')
                  }:
                </p>

                <ul style="font-size: 15px;  margin: 10px 0">
                  ${title ? `<li><u>${fromProblemSolving ? i18n.t('label.action') : i18n.t('label.Title')}</u>: <b>${title}</b></li>` : ''}
                  ${assignee ? `<li><u>${i18n.t('label.responsible')}</u>: <b>${assignee}</b></li>` : ''}
                  ${tags ? `<li><u>Tags</u>: <b>${tags}</b></li>` : ''}
                  ${creator ? `<li><u>${i18n.t('improvement_opp.created_by')}</u>: <b>${creator}</b></li>` : ''}
                  ${dueDate ? `<li><u>${i18n.t('label.due_date')}</u>: <b>${dueDate}</b></li>` : ''}
                  ${description ? `<li><u>${fromProblemSolving ? i18n.t('label.root_cause') : i18n.t('label.description')}</u>: <b>${description}</b></li>` : ''}
                  ${creation_date ? `<li><u>${i18n.t('metadata.creation_date')}</u>: <b>${creation_date}</b></li>` : ''}
                  ${instance ? `<li><u>${i18n.t('metadata.instance')}</u>: <b>${instance}</b></li>` : ''}
                  ${instance_leader ? `<li><u>${i18n.t('metadata.instance_leader')}</u>: <b>${instance_leader}</b></li>` : ''}
                  ${zone ? `<li><u>${i18n.t('metadata.zone')}</u>: <b>${zone}</b></li>` : ''}
                  ${`<li><u>${i18n.t('improvement_opp.state')}</u>: <b>${deleted ? i18n.t('deleted_singular') : completed ? i18n.t('completed') : i18n.t('pending')}</b></li>`}
                </ul>

                <div style="width: 100%; text-align: center; margin: 7% 0%;">
                  <a style="text-decoration: none; border-radius: 5px; padding: 11px 23px; color: white; background-color: #4e8769; font-size: 17px" href="https://habit.addval.io/apps/todo?originId=${id}">
                    ${commitmentFunctionality ? i18n.t('message.see_commitment_in_habit') : i18n.t('message.see_improvement_in_habit')}
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </html>
    `
  }

  const addTask = async val => {
    try {
      const payload = {
        ...val,
        client_id: ObjectId(val.client_id),
        assignee: ObjectId(val.assignee._id),
        dueDate: val.dueDate ? new Date(`${val.dueDate.slice(0, 10)} 12:00:00`) : null,
        subscribers: val.subscribers?.map(e => ObjectId(e)) || [],
        metadata: val.metadata?.map(m => ({
          name: m.name,
          type: m.type,
          answer: m.answer,
        })) || []
      }

      delete payload.imgData

      const { insertedId } = await createItem({ collection: 'improvement', payload })
      if (!insertedId) throw new Error('Item not created')
      
      showSuccessMessage(commitmentFunctionality ? i18n.t('message.commitment_created') : i18n.t('message.improvement_created'))
      
      const improvementId = insertedId.toString()
      
      // Upload image to AWS and then update the confirmation in MongoDB with the AWS image key
      if (val.imgData) {
        const { fileInfo, destinationFolder } = val.imgData || {}
        singleUpload(fileInfo, destinationFolder)
          .then((key) => updateImprovementWithKey(improvementId, key))
          .catch((err) => console.log(err))
      }

      // Send email to assignee and subscribers with the details of the improvement opportunity created
      val._id = improvementId
      sendEmailImprovements([val], 'add')
    } catch (error) {
      console.log(error)
      showErrorMessage(commitmentFunctionality ? i18n.t('message.commitment_error') : i18n.t('message.improvement_error'))
    }
  }

  const getEmailIntanceTemplate = bodyData => {
    const { name, start, participants, instance, instance_leader, commitment, creator, state, id} = bodyData
    return `
      <html>
        <table style="max-width: 600px; padding: 10px; margin:0 auto; border-collapse: collapse; border-radius: 1em; overflow: hidden;">
          <tr>
            <td style="background-color: #ecf0f1; text-align: center; padding: 0">
              <img width="20%" style="margin: 2% 0% 1%" src="https://production-habit.s3.amazonaws.com/habit/Habit+Logo.png" alt="Habit Logo">
            </td>
          </tr>
          
          <tr>
            <td style="background-color: #ecf0f1">
              <div style="color: #34495e; margin: 2% 10%; font-family: sans-serif">
                <h2 style="color: #e67e22; margin: 0 0 7px">
                  ${i18n.t('message.hello')}${name ? ` ${name}` : ''},
                </h2>

                <p style="margin: 2px; font-size: 15px">
                  ${state === 'add' ? i18n.t('message.assigned_participant_instance')
                  : state === 'update' ? i18n.t('message.instance_assigned_updated')
                  : i18n.t('message.instance_assigned_deleted')
                  }
                </p>

                <ul style="font-size: 15px;  margin: 10px 0">                                                   
                  ${instance ? `<li><u>${i18n.t('metadata.instance')}</u>: <b>${instance}</b></li>` : ''}
                  ${instance_leader ? `<li><u>${i18n.t('metadata.instance_leader')}</u>: <b>${instance_leader}</b></li>` : ''}
                  ${participants ? `<li><u>${i18n.t('label.participants')}</u>: <b>${participants}</b></li>` : ''}
                  ${start ? `<li><u>${i18n.t('label.start_date')}</u>: <b>${start}</b></li>` : ''}
                  ${creator ? `<li><u>${i18n.t('improvement_opp.created_by')}</u>: <b>${creator}</b></li>` : ''}
                  ${commitment && commitment !== 0 ? `<li><u>${i18n.t('label.num_commitment_instance')}</u>: <b>${commitment}</b></li>` : '' }
                </ul>

                <div style="width: 100%; text-align: center; margin: 7% 0%;">
                  <a style="text-decoration: none; border-radius: 5px; padding: 11px 23px; color: white; background-color: #4e8769; font-size: 17px" href="https://habit.addval.io/apps/calendar?originId=${id}">
                    ${i18n.t('message.see_instance_in_habit')}
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </html>
    `
  }

  // Demo user message template for Habit commercial
  const getEmailDemoHabitTemplate = userData => {
    const { name, email, phone, company, message, meetUs, topic } = userData
    return `
      <html>
        <table style="max-width: 600px; padding: 10px; margin:0 auto; border-collapse: collapse; border-radius: 1em; overflow: hidden;">
          <tr>
            <td style="background-color: #ecf0f1; text-align: center; padding-top: 2rem;">
              <img width="20%"" src="https://production-habit.s3.amazonaws.com/habit/Habit+Logo.png" alt="Habit Logo">
            </td>
          </tr>
          
          <tr>
            <td style="background-color: #ecf0f1; padding: 1rem">
              <div style="color: #34495e; margin: 2rem; font-family: sans-serif">
                <h3 style="color: #56616d; margin: 0 0 7px; margin-left: 2rem">
                  Contacto Demo
                </h3>

                <ul style="font-size: 15px;  margin: 10px 0; line-height: 1.8;">                                                   
                  <li><u>Nombre:</u> <b>${name}</b></li>
                  <li><u>Mail:</u> <b>${email}</b></li>
                  <li><u>Teléfono:</u> <b>${phone}</b></li>
                  <li><u>Empresa:</u> <b>${company}</b></li>
                  <li><u>Dónde nos conoció:</u> <b>${meetUs}</b></li>
                  <li><u>Tema o servicio de interés:</u> <b>${topic}</b></li>
                  <li><u>Mensaje:</u> <b>${message}</b></li>
                </ul>
              </div>
            </td>
          </tr>
        </table>
      </html>
    `
  }

  // Demo user thank you for contact template
  const getEmailDemoUserTemplate = name => {
    
    return `
      <html>
        <table style="max-width: 600px; padding: 10px; margin:0 auto; border-collapse: collapse; border-radius: 1em; overflow: hidden;">
          <tr>
            <td style="background-color: #ecf0f1; text-align: center; padding-top: 2rem;">
              <img width="15%"" src="https://production-habit.s3.amazonaws.com/habit/Habit+Logo.png" alt="Habit Logo">
            </td>
          </tr>
          
          <tr>
            <td style="background-color: #ecf0f1; padding: 1rem">
              <div style="color: #34495e; margin: 2rem; font-family: sans-serif">
                <h2 style="color: #4e8769; margin: 0 0 7px; text-align: center;">
                  ${i18n.t('message.hello')}${name ? ` ${name}` : ''},
                </h2>

                <p style="margin: 2px; font-size: 15px; text-align: center">
                  ${i18n.t('message.thankMessageForContact')}
                </p>

                <div style="width: 100%; text-align: center; margin: 7% 0%;">
                  <span style="color: #4e8769; display: block;">
                    ${i18n.t('message.habitTeam')}
                  </span>
                  <a style="margin-top: 1rem; font-size: 11px; display: block; color: #56616d" href="https://habit-demo.addval.io/login">
                    ${i18n.t('message.visitTheSite')}
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </html>
    `
  }

  const sendEmailInstance = async (eventData, id, state) => {
    try {
      const query = { _id: ObjectId(id) }

      const pipeline = [
        { $match: query },
        { $lookup: { from: 'worker', localField: 'participants', foreignField: '_id', as: 'participants', pipeline: [ { $project: { name: 1, email: 1 } } ] } },
        { $project: { participants: 1 }}
      ]

      const items = await getItemsWithAggregate({ collection: 'event', pipeline })
      if (!items?.[0]) throw new Error('Item not found')

      const info = items[0]

      const emailPromises = info.participants?.map((p) =>  {
        if (p.email) {
          const subject =
            state === 'add' ? i18n.t('message.instance_assigned')
            : state === 'update' ? i18n.t('message.instance_updated')
            : i18n.t('message.instance_deleted')
          let bodyData = {
            name: p.name,
            start: formatEmailDate(eventData.start),                
            participants: info.participants.map(p => p.name).join(" / "),
            creator: userData.username,
            commitment: eventData.extendedProps.improvements.length,
            state,
            id
          }
          eventData.extendedProps.metadata?.forEach(e => {
            Object.assign(bodyData, {[e.name]: e.answer})
          })
          const body = getEmailIntanceTemplate(bodyData)
          return new Promise((resolve, reject) => {
            sendEmail([p.email], subject, body)
              .then(res => res.MessageId && resolve(res.MessageId))
              .catch(err => console.log(err))
          })                
        }
      })

      const emailResponses = await Promise.all(emailPromises)

      const cantResponses = emailResponses.filter(res => !!res)

      if (cantResponses.length > 0) showSuccessMessage(`${cantResponses.length} ${i18n.t('message.email_send_instance_success')}`)
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.email_send_instance_error'))
    }
  }

  const sendEmailImprovements = async (improvements, state, improvementsToUpdate, improvementsToDelete) => {
    const emailPromises = []
    for (const i of improvements) {  
      // If improvement is not new or flagged for update/delete or assigne doesn/t have email then continue (don't send email)
      if (!state && !i.isNew && !improvementsToUpdate?.find(e => e._id === i._id) && !improvementsToDelete?.find(e => e._id === i._id)) {
        continue
      }

      let recipients = []

      if (i.assignee?.email) recipients.push({ name: i.assignee.name, email: i.assignee.email, category: "assignee" })

      if (i.subscribers?.length) {
        try {
          const subscribersData = i.subscribers[0]?._id ? i.subscribers : await getWorkersEmails(i.subscribers)
          recipients = [...recipients, ...subscribersData]
        } catch (error) {
          console.log(error)
        }
      }

      if (!recipients.length) continue

      const updatedState = state
          ? state
          : i.isNew
            ? "add"
            : improvementsToDelete?.find(e => e._id === i._id)
              ? "delete"
              : "update"
  
        let subject
        switch (updatedState) {
          case "update":
            subject = commitmentFunctionality ? i18n.t('message.commitment_updated') : i18n.t('message.improvement_opportunity_updated')
            break;
          case "complete":
            subject = commitmentFunctionality ? i18n.t('message.commitment_completed') : i18n.t('message.improvement_opportunity_completed')
            break;
          case "delete":
            subject = commitmentFunctionality ? i18n.t('message.commitment_deleted') : i18n.t('message.improvement_opportunity_deleted')
            break;
          default:
            subject = commitmentFunctionality ? i18n.t('message.commitment_assigned') : i18n.t('message.improvement_opportunity_assigned')
            break;
        }

        const bodyData = {
          title: i.note,
          assignee: i.assignee?.name,
          tags: i.tags?.map(e => i18n.t(`domain.${e}`)).join(" / "),
          creator: userData.username,
          dueDate: formatEmailDate(i.dueDate),
          description: i.description?.replace(/<[^>]*>/g, ''),
          commitmentFunctionality,
          updatedState,
          completed: i.completed,
          deleted: i.deleted,
          id: i._id
        }
        if (i.metadata?.length) {
          i.metadata.forEach(e => {
            if (e.name === "creation_date") e.answer = formatEmailDate(e.answer)
            Object.assign(bodyData, {[e.name]: e.answer})
          })
        }

      recipients.forEach(r => {
        if (r.email) {
          bodyData.name = r.name
          bodyData.category = r.category
  
          const body = getEmailImprovementTemplate(bodyData)
    
          const emailPromise = new Promise((resolve, reject) => {
            sendEmail([r.email], subject, body)
              .then(res => {
                res.MessageId && resolve(res.MessageId)
              })
              .catch(err => console.log(err))
          })
    
          emailPromises.push(emailPromise)
        }
      })
    }

    return Promise.all(emailPromises)
      .then(emailResponses => {
        const cantResponses = emailResponses.filter(res => !!res)
        if (cantResponses.length > 0) showSuccessMessage(`${cantResponses.length} ${commitmentFunctionality ? i18n.t('message.email_send_commitment_success') : i18n.t('message.email_send_improvement_success')}`) 
      })
      .catch((err) => {
        console.log(err)
        showErrorMessage(commitmentFunctionality ? i18n.t('message.email_send_commitment_error') : i18n.t('message.email_send_improvement_error'))
      })
  }

  const formatEmailDate = (date) => {
    if (!date) return ''

    const dateToFormat = date instanceof Date ? date : new Date(`${date.slice(0, 10)} 12:00:00`)
    return dateToFormat.toLocaleDateString(`${default_language || 'en'}-US`)
  }

  const addOriginIdEvent = async eventId => {
    try {
      const query = { _id: ObjectId(eventId) }
      const item = await getItem({ collection: 'event', query, options: { projection: { improvements: 1 } } })
      if (!item) throw new Error('Item not found')

      const improvementsToUpdate = item.improvements || []

      if (improvementsToUpdate?.length) {
        const updateQuery = { _id: { $in: improvementsToUpdate } }
        const action = { $set: { origin_id: ObjectId(eventId), origin: "event" } }
        await updateItems({ collection: 'improvement', query: updateQuery, action })
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.improvement_update_error'))
    }
  }

  const getWorkersEmails = async (workerIds) => {
    const query = { _id: { $in: workerIds.map(e => ObjectId(e)) } }
    return await getItems({ collection: 'worker', query, options: { projection : { name: 1, email: 1 } } })
  }

  return {
    resolveTagVariant,
    resolveAvatarVariant,
    updateImprovementWithKey,
    getEmailImprovementTemplate,
    addTask,
    sendEmailInstance,
    sendEmailImprovements,
    addOriginIdEvent,
    getWorkersEmails,
    getEmailDemoHabitTemplate,
    getEmailDemoUserTemplate,
    formatEmailDate
  }
}